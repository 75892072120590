body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
    cursor: pointer;
}

a {
    color: #444;
}

#all-status {
  margin: auto;
  min-width: 50%;
  max-width: 75%;
  padding: 10px;
}

.log-data {
    overflow: auto;
    color: grey;
    background-color: black;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    width: 98%;
    height: 95%;
    padding: 1em;
}

.status-row {
  width: auto;
  background-color: white;
  padding: 1em;
  margin: 1em;
  border-radius: 25px;
}

.status-key {
  font-weight: bold;
  margin-right: 0.5em;
}

.status-value {
}

.deployed {
    background-color: #ddffdd;
}

.button-deployed {
    background-color: lawngreen;
    color: white;
}

.error {
    background-color: #ffdddd;
}

.button-error {
    background-color: red;
}

.cancelled {
    background-color: #efefef;
    text-color: #dddddd;
}

.removed {
    background-color: #efeffa;
    text-color: #dddddd;
}

.submitted {
    background-color: #ffffdd;
}

.deploying {
    background-color: #fff1dd;
}
